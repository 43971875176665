import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CebForm from "../Form/CebForm";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import conditionalObj from "../../helpers/getConditionalObj";
import moment from "moment";
import "moment/locale/ar"; 
import { t } from "i18next";
import i18n from "i18next"; 

// Helper to translate numbers to Arabic numerals
const translateNumber = (number, language) => {
  if (language === "ar") {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number.toString().replace(/\d/g, (digit) => arabicNumbers[digit]);
  }
  return number; 
};

// Helper to format dates based on locale
const formatDate = (date, language) => {
  moment.locale(language); 
  return moment(date).format("DD/MM/YYYY h:mm A");
};

const DrugsDetailView = ({
  prescriptionId,
  prescId,
  prescription,
  viewModeState,
  multiple,
  cardItems,
  cardsData,
  module,
  subModule,
  deactivationReason,
}) => {
  const [listData, setListData] = useState();
  const [chosenCardId, setChosenCardId] = useState(0);

  const { patientId, admissionId } = useParams();
  const theme = useTheme();
  const language = i18n.language; 

  //api
  const getMealRelationList = useApi(mainApi.getMealRelationList);
  const getAdmissionPrescription = useApi(mainApi.getAdmissionPrescription);

  // Helpers
  const createList = (prescr) => {
    return [
      {
        data: (
          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              direction: language === "ar" ? "rtl" : "ltr",
              textAlign: language === "ar" ? "right" : "left",
            }}
          >
            <Stack direction="column" spacing={2}>
              {/* Drug Name (English) */}
              <Typography fontSize={"1.14rem"} fontWeight={600}>
                {prescr.generic_name}
              </Typography>

              {/* Translated Details */}
              <Stack direction="column" spacing={1}>
                <Typography fontSize={"1rem"} fontWeight={400}>
                  {`${t(prescr.route_administration)}, ${translateNumber(
                    prescr.num_units,
                    language
                  )} ${t("unit")}, ${t(prescr.meal_relation)}, ${translateNumber(
                    prescr.frequency_rate,
                    language
                  )} ${t("doses")} ${t("every")} ${t(prescr.frequency_type)}`}
                </Typography>
                <Typography fontSize={"1rem"} fontWeight={400}>
                  {`${t("start from")}: ${formatDate(
                    new Date(prescr.starting_from),
                    language
                  )}, ${t("for")} ${translateNumber(
                    prescr.rate,
                    language
                  )} ${t(prescr.rate_type)}`}
                </Typography>
                <Typography fontSize={"1rem"} fontWeight={400}>
                  {`${t("Comments")}: ${t(prescr.comments)}`}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ),
        printDrug: `${prescr.generic_name}, ${t(prescr.route_administration)} 
        ${translateNumber(prescr.num_units, language)} ${t("unit")}, ${t(
          prescr.meal_relation
        )}, ${translateNumber(prescr.frequency_rate, language)} ${t("doses")} ${
          prescr.frequency_type !== "once" ? t("every") : ""
        }${t(prescr.frequency_type)}, ${t("for")} ${translateNumber(
          prescr.rate,
          language
        )} ${t(prescr.rate_type)}`,
      },
      conditionalObj(admissionId, {
        label: t("Doses time"),
        fieldType: "element",
        element: (
          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              direction: language === "ar" ? "rtl" : "ltr", 
              textAlign: language === "ar" ? "right" : "left", 
            }}
          >
            <Typography fontSize={"1rem"} fontWeight={600} sx={{ marginBottom: "8px" }}>
              {t("Doses time")}
            </Typography>
            {prescr.doses_time?.map((item, index) => (
              <ListItem key={index} value={item.time_string}>
                <ListItemIcon>
                  <FiberManualRecordSharpIcon
                    style={{
                      fontSize: 15,
                      color: moment().isAfter(moment(item.time)) ? "grey" : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={formatDate(new Date(item.time), language)}
                  style={{
                    color: moment().isAfter(moment(item.time)) ? "grey" : "black",
                    textAlign: language === "ar" ? "right" : "left", 
                  }}
                />
              </ListItem>
            ))}
          </Box>
        ),
        printDoses: prescr.doses_time?.map((item) =>
          formatDate(new Date(item.time), language)
        ),
      }),
    ];
  };

  const getPrescription = async () => {
    if (multiple) {
      const newPrescMultipleList = [];
      for (const prescr of prescription) {
        newPrescMultipleList.push(...createList(prescr));
      }
      if (deactivationReason) {
        newPrescMultipleList.push({
          label: t("Deactivation reason"),
          data: deactivationReason,
        });
      }
      setListData(newPrescMultipleList.filter((item) => item));
    } else {
      let prescr;
      if (prescriptionId && admissionId) {
        const res = await getAdmissionPrescription.request(
          patientId,
          admissionId,
          prescriptionId
        );
        prescr = res.data.data;
      } else prescr = prescription;
      setListData(createList(prescr).filter((item) => item));
    }
  };

  // useEffect
  useEffect(() => {
    getMealRelationList.request();
    setChosenCardId(prescriptionId || prescId);
  }, []);

  useEffect(() => {
    if (getMealRelationList.data.length > 0) getPrescription();
  }, [prescriptionId, getMealRelationList.data]);

  // Printing
  const selectedCard = admissionId
    ? getAdmissionPrescription.data
    : cardsData.find((item) => item.id === chosenCardId);
  const drugList = listData
    ?.map((item) => item.printDrug)
    .filter((item) => item);
  const dataToPrint = {
    header: {
      title: admissionId ? t("Medication") : t("Prescription"),
      left: [
        formatDate(selectedCard?.created_at, language),
        `${t("Doctor")}: ${selectedCard?.practitioner?.full_name}`,
      ],
      right: admissionId ? [`${t("Status")}: ${selectedCard?.state}`] : [],
    },
    content: [
      {
        label: t("Medication"),
        data: drugList,
      },
      admissionId && {
        label: t("Doses time"),
        data: listData?.find((item) => item.label === t("Doses time"))?.printDoses,
      },
      admissionId && listData?.find((item) => item.label === t("Comments")),
    ].filter((item) => item),
  };

  return (
    <ThemeProvider theme={createTheme({ direction: language === "ar" ? "rtl" : "ltr" })}>
      <Box sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
        <CebForm
          listData={listData}
          cardItems={cardItems}
          cardsData={admissionId ? [getAdmissionPrescription.data] : cardsData}
          viewModeState={viewModeState}
          chosenCardId={chosenCardId}
          module={module}
          subModule={subModule}
          dataToPrint={dataToPrint}
        />
      </Box>
    </ThemeProvider>
  );
};

export default DrugsDetailView;