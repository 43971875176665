import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";

import PatientCard, {
  PatientCardTitles,
} from "../../../components/patient/PatientCard";
import {
  Button,
  Stack,
  Typography,
  Box,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import Document from "../../../assets/icons/Document";
import Deactivation from "../../../assets/icons/Deactivation";
import Filter from "../../../assets/icons/Filter";
import DrugsForm from "../../../components/patient/DrugsForm";
import DrugsDetailView from "../../../components/patient/DrugsDetailView";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import moment from "moment";
import Modal from "../../../components/UI/Modal";
import CebForm from "../../../components/Form/CebForm";
import roleMapper from "../../../helpers/roleMapper";
import usePermissions from "../../../hooks/usePermissions";
import { defaultPageSize, modules } from "../../../config/constants";
import { useTheme } from "@emotion/react";
import { t } from "i18next";

const Drugs = () => {
  const { patientId, admissionId } = useParams();

  const [viewMode, setViewMode] = useState("cards");
  const [deactivateViewMode, setDeactivateViewMode] = useState("form");
  const [openModal, setOpenModal] = useState(false);
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false);
  const [activeDrugs, setActiveDrugs] = useState([]);
  const [chosenCardId, setChosenCardId] = useState(null);
  const [inActiveDrugs, setInActiveDrugs] = useState([]);
  const [currentPrescrId, setCurrentPrescrId] = useState();
  const [currentCardItems, setCurrentCardItems] = useState([]);
  const [resetForm, setResetForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //apis
  //Prescription
  const getPrescriptionList = useApi(mainApi.getPrescriptionList);
  const addPrescription = useApi(mainApi.addPrescription);
  const togglePrescription = useApi(mainApi.togglePrescription);
  const getMealRelationList = useApi(mainApi.getMealRelationList);
  //Admission Pres
  const getAdmissionPrescriptionList = useApi(
    mainApi.getAdmissionPrescriptionList
  );
  const addAdmissionPrescription = useApi(mainApi.addAdmissionPrescription);
  const toggleAdmissionPrescription = useApi(
    mainApi.toggleAdmissionPrescription
  );
  const getAdmission = useApi(mainApi.getAdmission);

  //permissions
  const module = admissionId ? modules.ADMISSIOM : modules.PRESCRIPTION;
  const subModule = admissionId
    ? modules.ADMISSIOM_MEDICATION
    : modules.PRESCRIPTION;
  const permissions = usePermissions(module, subModule);

  const [enableAddNew, setEnableAddNew] = useState(false);

  const hasActiveAdmissionApi = useApi(mainApi.hasActiveAdmission);

  useEffect(() => {
    hasActiveAdmissionApi.request(patientId);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("dep_id") || hasActiveAdmissionApi.data === true)
      setEnableAddNew(true);
  }, [hasActiveAdmissionApi.data]);

  useEffect(() => {
    if (
      (admissionId &&
        Object.keys(getAdmission.data).length > 0 &&
        !getAdmission.data?.discharge_date) ||
      !admissionId
    ) {
      if (viewMode === "cards" && !permissions.view && permissions.add)
        setOpenModal(true);
    }
  }, [permissions, getAdmission.data]);

  //nav of notification
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("view"))
      handleViewCard({ id: urlParams.get("id"), state: "active" });
  }, [urlParams.get("view")]);

  useEffect(() => {
    getMealRelationList.request();
    if (admissionId) {
      getAdmission.request(patientId, admissionId);
    }
  }, []);

  useEffect(() => {
    if (permissions?.view) {
      if (admissionId) {
        getAdmissionPrescriptionList.request(
          patientId,
          admissionId,
          pageNumber
        );
      } else {
        getPrescriptionList.request(patientId, pageNumber);
      }
    }
  }, [
    permissions,
    addAdmissionPrescription.data,
    addPrescription.data,
    pageNumber,
  ]);

  useEffect(() => {
    let active = [];
    let inActive = [];
    if (admissionId) {
      getAdmissionPrescriptionList.data.map((item) => {
        if (item.state === "active") active.push(item);
        else inActive.push(item);
      });
    } else {
      getPrescriptionList.data.map((item) => {
        if (item.status === "active" || item.status === "نشط")
          active.push(item);
        else inActive.push(item);
      });
    }
    setActiveDrugs(active);
    setInActiveDrugs(inActive);
  }, [getAdmissionPrescriptionList.data, getPrescriptionList.data]);

  const activeCardItems = [
    { header: "Medication", keyName: "data.generic_name" },
    { header: "Ordering Physician", keyName: "data.practitioner?.full_name" },
    {
      header: "Start Date",
      keyName: "data.starting_from",
      type: "formattedDate",
      format: "YY-MM-DD, h:mm a",
    },

    !mobileView && {
      header: "Deactivate",
      keyName: "data.deactivation",
      type: "element",
      onClickHandler: (card) => {
        handleOpenDeactivationModal();
        setChosenCardId(card?.id);
      },
      element: (
        <Box sx={{ cursor: "pointer" }}>
          <Deactivation />
        </Box>
      ),
    },
  ].filter((item) => item);

  const inActiveCardItems = [
    { header: "Medication", keyName: "data.generic_name" },
    { header: "Ordering Physician", keyName: "data.practitioner?.full_name" },
    {
      header: "Start Date",
      keyName: "data.starting_from",
      type: "formattedDate",
      format: "YY-MM-DD, h:mm a",
    },
    {
      header: "End Date",
      keyName: "data.ending_date",
      type: "formattedDate",
      format: "YY-MM-DD, h:mm a",
    },
  ];

  const inActivePrescriptionCardItems = [
    {
      header: "Date",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY",
    },
    { header: "Ordering Physician", keyName: "data.practitioner?.full_name" },
  ];

  const activePrescriptionCardItems = [
    ...inActivePrescriptionCardItems,
    !mobileView && {
      header: "Deactivate",
      keyName: "data.deactivation",
      type: "element",
      onClickHandler: (card) => {
        handleOpenDeactivationModal();
        setChosenCardId(card?.id);
      },
      element: (
        <Box sx={{ cursor: "pointer" }}>
          <Deactivation />
        </Box>
      ),
    },
  ].filter((item) => item);

  //heplers
  const handleViewCard = async (card) => {
    setCurrentPrescrId(card.id);
    if (admissionId)
      setCurrentCardItems(
        card.state === "active" ? activeCardItems : inActiveCardItems
      );
    else
      setCurrentCardItems(
        card.state === "active"
          ? activePrescriptionCardItems
          : inActivePrescriptionCardItems
      );
    setViewMode("list");
  };

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const handleOpenDeactivationModal = () => {
    setOpenDeactivationModal((state) => !state);
  };

  const handleReport = (card) => {
    console.log(card.id);
  };

  useEffect(() => {
    if (viewMode === "list") setViewMode("cards");
  }, [toggleAdmissionPrescription.data, togglePrescription.data]);

  const handleDeactivation = async ({ deactivation_reason }) => {
    if (admissionId) {
      let res = await toggleAdmissionPrescription.requestWithToast(
        t("Deactivated successfully"),
        patientId,
        admissionId,
        chosenCardId,
        { deactivation_reason }
      );

      if (res?.ok) {
        handleOpenDeactivationModal();
        getAdmissionPrescriptionList.request(
          patientId,
          admissionId,
          pageNumber
        );
      }
    } else {
      let res = await togglePrescription.requestWithToast(
        t("Deactivated successfully"),
        patientId,
        chosenCardId,
        { deactivation_reason }
      );

      if (res?.ok) {
        handleOpenDeactivationModal();
        getPrescriptionList.request(patientId, pageNumber);
      }
    }
  };

  //Submit
  const onSubmit = async (data) => {
    let res;
    if (admissionId) {
      res = await addAdmissionPrescription.requestWithToast(
        t("Added successfully"),
        patientId,
        admissionId,
        data
      );
    } else {
      res = await addPrescription.requestWithToast(
        t("Added successfully"),
        patientId,
        { drugs: data }
      );
    }
    if (res.ok) {
      setResetForm(true);
      setOpenModal(false);
    }
  };

  return (
    <Stack pb={3} gap={2}>
      {
        <DrugsForm
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          onSubmitHandler={onSubmit}
          loading={
            addPrescription.loading ||
            addAdmissionPrescription.loading ||
            getAdmission.loading
          }
          resetState={[resetForm, setResetForm]}
          requestErrors={
            addPrescription.error || addAdmissionPrescription.error
          }
          module={module}
          subModule={subModule}
        />
      }
      {viewMode === "cards" && permissions?.view && (
        <>
          {getPrescriptionList.loading === true ||
          getAdmissionPrescriptionList.loading === true ||
          getAdmission.loading ? (
            <LoadingBox
              loading={
                getPrescriptionList.loading ||
                getAdmissionPrescriptionList.loading
              }
            />
          ) : (
            <>
              <Stack direction="row" gap={2}>
                {!getAdmission?.data?.discharge_date &&
                  permissions?.add &&
                  !mobileView &&
                  enableAddNew && (
                    <Button
                      variant="outlined"
                      onClick={() => setOpenModal(true)}
                      style={{
                        padding: "5px 20px",
                        borderRadius: 10,
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                    >
                      {t("Add New")}
                    </Button>
                  )}
              </Stack>

              {activeDrugs?.length > 0 && (
                <>
                  <Typography variant="body2">
                    {admissionId
                      ? t("Active Medications")
                      : t("Active Prescriptions")}
                  </Typography>
                  {activeDrugs.map((item, index) => (
                    <>
                      {index === 0 && !mobileView && (
                        <PatientCardTitles
                          items={
                            admissionId
                              ? activeCardItems
                              : activePrescriptionCardItems
                          }
                          // handleView={true}
                          data={item}
                        />
                      )}
                      <PatientCard
                        items={
                          admissionId
                            ? activeCardItems
                            : activePrescriptionCardItems
                        }
                        data={item}
                        key={index}
                        index={index}
                        handleView={handleViewCard}
                      />
                    </>
                  ))}
                </>
              )}

              {inActiveDrugs?.length > 0 && (
                <>
                  <Typography variant="body2">
                    {admissionId
                      ? t("Non-Active Medications")
                      : t("Non-Active Prescriptions")}
                  </Typography>
                  {inActiveDrugs.map((item, index) => (
                    <>
                      {index === 0 && !mobileView && (
                        <PatientCardTitles
                          items={
                            admissionId
                              ? inActiveCardItems
                              : inActivePrescriptionCardItems
                          } // handleView={true}
                          data={item}
                        />
                      )}
                      <PatientCard
                        items={
                          admissionId
                            ? inActiveCardItems
                            : inActivePrescriptionCardItems
                        }
                        data={item}
                        key={index}
                        index={index}
                        handleView={handleViewCard}
                      />
                    </>
                  ))}
                </>
              )}
            </>
          )}
          {(getPrescriptionList.count > 0 ||
            getAdmissionPrescriptionList.count > 0) && (
            <Stack alignItems={"center"} pt={4}>
              <Pagination
                dir="ltr"
                color="primary"
                count={Math.ceil(
                  admissionId
                    ? getAdmissionPrescriptionList.count / defaultPageSize
                    : getPrescriptionList.count / defaultPageSize
                )}
                onChange={(event, value) => {
                  setPageNumber(value);
                }}
                page={pageNumber}
              />
            </Stack>
          )}
        </>
      )}
      {viewMode === "list" && currentPrescrId && (
        <>
          {admissionId ? (
            <DrugsDetailView
              viewModeState={[viewMode, setViewMode]}
              prescriptionId={currentPrescrId}
              cardItems={currentCardItems}
              cardsData={getAdmissionPrescriptionList.data}
              module={module}
              subModule={subModule}
            />
          ) : (
            <DrugsDetailView
              viewModeState={[viewMode, setViewMode]}
              prescription={
                getPrescriptionList.data.find(
                  (item) => item.id === currentPrescrId
                ).drugs
              }
              deactivationReason={
                getPrescriptionList.data.find(
                  (item) => item.id === currentPrescrId
                ).deactivation_reason
              }
              prescId={currentPrescrId}
              multiple={true}
              cardItems={currentCardItems}
              cardsData={getPrescriptionList.data}
              module={module}
              subModule={subModule}
            />
          )}
        </>
      )}

      <Modal
        open={openDeactivationModal}
        handleOpen={handleOpenDeactivationModal}
      >
        <CebForm
          modalForm
          modalTitle="Deactivation reason"
          handleOpenModal={handleOpenDeactivationModal}
          onSubmit={handleDeactivation} // give it card
          viewModeState={[deactivateViewMode, setDeactivateViewMode]}
          fields={[
            {
              label: "Reason",
              name: "deactivation_reason",
              multiline: true,
            },
          ]}
          listData={[]}
          addInList={true}
          saveButtonLabel="Deactivate"
          module={module}
          subModule={subModule}
        />
      </Modal>
      {viewMode === "cards" && !permissions?.view && (
        <Typography variant="h5" color="fieldBorder" textAlign="center">
          {t("There is no data available")}
        </Typography>
      )}
    </Stack>
  );
};

export default Drugs;
