import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Grid,
  ImageList,
  ImageListItem,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { StyledIconButton } from "./CebBooleanField";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PatientCard from "../patient/PatientCard";
import Modal from "../UI/Modal";
import UploadIcon from "../../assets/icons/Upload";
import getBase64 from "../../helpers/getBase64";
import { useTranslation } from "react-i18next";

export default function CebList({
  data,
  chosenCardId = 0,
  cardsData,
  cardItems = [],
  onClickHandler,
  profile,
  profilePic,
  updateProfilePicHandler,
}) {
  const [openProfilePicModal, setOpenProfilePicModal] = useState(false);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabView = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenProfilePicModal = () => {
    setOpenProfilePicModal((s) => !s);
  };

  console.log("chosen", chosenCardId, cardsData);
  const nestedFormListField = (field) => {
    if (Array.isArray(field.data) && field.data?.length > 0) {
      return field.data.map((dataItem, index) => (
        <ListItem
          key={index}
          value={dataItem}
          style={{ textAlign: i18n.language === "en" ? "left" : "right" }}
        >
          <ListItemIcon>
            <FiberManualRecordSharpIcon
              style={{ fontSize: 15, color: "black" }}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${
              field.primaryListText.key
                ? `${t(field.primaryListText.key)}:`
                : ""
            } ${t(eval(field.primaryListText.value))}`}
            secondary={
              <div style={{ marginTop: 5 }}>
                <div>
                  {eval(field.secondaryListText?.value) !== undefined &&
                    eval(field.secondaryListText?.value) !== "" &&
                    eval(field.secondaryListText?.value) !== null &&
                    `${
                      field.secondaryListText.key
                        ? `${t(field.secondaryListText.key)}:`
                        : ""
                    } ${t(eval(field.secondaryListText?.value))}`}
                </div>
                {eval(field.thirdListText?.value) && (
                  <div style={{ marginTop: 5 }}>
                    {t(field.thirdListText?.key)}:{" "}
                    {t(eval(field.thirdListText?.value))}
                  </div>
                )}
              </div>
            }
          />
        </ListItem>
      ));
    } else if (field.data?.length === 0 || field.data === "") {
      //if list is empty or nested form is not a list
      return (
        <StyledIconButton sx={{ backgroundColor: "primary.main" }}>
          <CloseIcon fontSize="small" style={{ color: "white" }} />
        </StyledIconButton>
      );
    } else return <Typography>No data</Typography>;
  };

  const formListField = (field) => {
    if (Array.isArray(field.data) && field.data?.length > 0) {
      return field.data?.map((dataItem, index) => (
        <ListItem key={index} value={dataItem}>
          <ListItemIcon>
            <FiberManualRecordSharpIcon
              style={{ fontSize: 15, color: "black" }}
            />
          </ListItemIcon>
          <ListItemText primary={dataItem} />
        </ListItem>
      ));
    } else if ((field.data || field.data === 0) && field.data !== "") {
      // check not an array to avoid empty array.
      return (
        <>
          {!profile && (
            <ListItem style={{ padding: 0 }}>
              {field.multiline ? (
                <ListItemText
                  primary={field.data.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== field.data.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                />
              ) : (
                <ListItemText
                  dir="ltr"
                  primary={
                    typeof field.data === "string" ? t(field.data) : field.data
                  }
                />
              )}
            </ListItem>
          )}
          {profile && <Typography fontWeight={300}>{field.data}</Typography>}
        </>
      );
    } else {
      return <Typography> No data </Typography>;
    }
  };

  const booleanListField = (field) => {
    if (field.data === true || field.data === false) {
      return (
        <StyledIconButton sx={{ backgroundColor: "primary.main" }}>
          {field.data ? (
            <DoneIcon fontSize="small" style={{ color: "white" }} />
          ) : (
            <CloseIcon fontSize="small" style={{ color: "white" }} />
          )}
        </StyledIconButton>
      );
    } else return <Typography>No data</Typography>;
  };
  // this the span of boolean values
  const booleanWithDetailListField = (field) => {
    if (field.data === true) {
      return (
        <ListItem style={{ padding: 0 }}>
          <span>
            {field.detail.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
        </ListItem>
      );
    } else if (field.data === false) {
      return (
        <StyledIconButton sx={{ backgroundColor: "primary.main" }}>
          <CloseIcon fontSize="small" style={{ color: "white" }} />
        </StyledIconButton>
      );
    } else return <Typography>No data</Typography>;
  };

  const linkTag = (data, index = 1) => (
    <div>
      <a
        key={index}
        href={data?.file}
        target="_blank"
        rel="noreferrer"
        style={{
          maxWidth: 350,
          background: "#A5D2FF",
          color: "#003468",
          padding: "1px 6px 1px 6px",
          display: "inline-block",
          borderRadius: 5,
          fontSize: 14,
          fontWeight: 300,
          alignItems: "center",
          justifyContent: "center",
          textDecoration: "none",
          marginBottom: 8,
        }}
      >
        {!data.file_name
          ? t("view")
          : data?.file_name.length > 25
          ? `${data?.file_name.substr(0, 25)}...`
          : data?.file_name}
      </a>
    </div>
  );

  const linkListField = (field) => {
    if (Array.isArray(field.data))
      return field.data.map((item, i) => linkTag(item, i));
    else return linkTag(field.data);
  };

  const addListField = (field) => {
    if (field?.hasOwnProperty("condition") && field.condition === false) return;
    else {
      if (field.fieldType === "nested") return nestedFormListField(field);
      else if (field.fieldType === "boolean") return booleanListField(field);
      else if (field.fieldType === "booleanWithDetail")
        return booleanWithDetailListField(field);
      else if (field.fieldType === "link") return linkListField(field);
      else if (field.fieldType === "labelField")
        return (
          <Typography
            variant="h5"
            color="primary.main"
            style={{ fontWeight: "bold", marginTop: 30 }}
          >
            {t(field.data)}
          </Typography>
        );
      else if (field.fieldType === "element") return <div>{field.element}</div>;
      else if (field.fieldType === "gallery")
        return (
          <Stack sx={{ maxHeight: 600, overflow: "auto" }}>
            <ImageList
              sx={{
                width: 800,
                backgroundColor: "#fff",
                display: "felx",
                alignItems: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
              cols={3}
              rowHeight={250}
            >
              {field?.data?.map((item) => (
                <ImageListItem key={item}>
                  <img
                    src={`${item.file}`}
                    alt={item.name}
                    loading="lazy"
                    style={{
                      width: 255,
                      height: "100%",
                      objectFit: "contain",
                      border: "2px solid #000",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(item.file, "_blank").focus()}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Stack>
        );
      else return formListField(field);
    }
  };

  return (
    <Stack gap={3}>
      {/* Show/Update profile pic */}
      <Modal
        open={openProfilePicModal}
        handleOpen={handleOpenProfilePicModal}
        width={500}
      >
        <ProfilePicModal
          profilePic={profilePic}
          closeHandler={handleOpenProfilePicModal}
          updateProfilePicHandler={updateProfilePicHandler}
        />
      </Modal>

      {data[0]?.fieldType === "header" && (
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
            borderBottom="0.924715px solid rgba(0, 0, 0, 0.5)"
            paddingBottom="20px"
          >
            {data[0].title && (
              <Typography variant="h5" color="fieldBorder">
                {data[0].title}
              </Typography>
            )}
            {data[0].actionBox && data[0].actionBox}
          </Stack>
        </Grid>
      )}

      {chosenCardId != 0 && (
        <PatientCard
          items={cardItems?.filter((item) => item.removeInDetail !== true)}
          data={cardsData?.find((item) => item.id == chosenCardId)}
          headersInCard
          onClickHandler={onClickHandler}
          dark={profile}
        />
      )}

      {!profile && (
        <Grid container gap={2} paddingRight={2}>
          {data.length > 0 &&
            data
              ?.filter(
                (item) =>
                  item?.hasOwnProperty("cardItem") === false ||
                  item?.cardItem === false
              )
              .map((field, i) => (
                <>
                  {field &&
                    field.fieldType !== "header" &&
                    !(field.hasOwnProperty("data") && !field.data) && (
                      // <Grid item xs={chosenCardId ? 12 : 6} key={i}>
                      <Grid
                        item
                        xs={field.halfRow ? (field.childMargin ? 5.5 : 6) : 12}
                        key={i}
                        style={{ marginLeft: field.childMargin === 1 ? 24 : 0 }}
                      >
                        <Stack
                          direction={
                            (Array.isArray(field.data) && field.data?.length) ||
                            field.fieldType === "element" ||
                            field.flowDirection === "column"
                              ? "column"
                              : "row"
                          }
                          alignItems={
                            (Array.isArray(field.data) && field.data?.length) ||
                            field.fieldType === "element" ||
                            field.flowDirection === "column"
                              ? "flex-start"
                              : "center"
                          }
                          gap={
                            (Array.isArray(field.data) && field.data?.length) ||
                            field.fieldType === "element" ||
                            field.flowDirection === "column"
                              ? 0
                              : 2
                          }
                        >
                          {field?.hasOwnProperty("condition") &&
                          field.condition === false
                            ? null
                            : field.fieldType !== "labelField" && (
                                <Typography
                                  variant="body2"
                                  color="fieldBorder"
                                  style={{
                                    fontWeight: field.childMargin && "normal",
                                  }}
                                >
                                  {t(field.label)}
                                </Typography>
                              )}
                          <List
                            style={{
                              overflowWrap: "break-word",
                              maxWidth: "90%",
                            }}
                          >
                            {addListField(field)}
                          </List>
                        </Stack>
                      </Grid>
                    )}
                </>
              ))}

          {(data.length === 0 ||
            data === undefined ||
            data === null ||
            data === {}) && (
            <Typography variant="h4" color="fieldBorder">
              {t("There is no data available")}
            </Typography>
          )}
        </Grid>
      )}
      {profile && (
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          position="relative"
          p={2}
          paddingTop={profilePic && !mobileView ? 12 : 2}
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "20px",
          }}
        >
          {profilePic && (
            <Stack
              width={mobileView ? "55px" : tabView ? "150px" : "180px"}
              height={mobileView ? "55px" : tabView ? "150px" : "180px"}
              position="absolute"
              top={!mobileView ? -100 : -150}
              left={
                i18n.language === "en" && (mobileView ? 10 : tabView ? 30 : 50)
              }
              right={
                i18n.language === "ar" && (mobileView ? 10 : tabView ? 30 : 50)
              }
              borderRadius="100px"
              overflow="hidden"
              sx={{
                "&:hover": { cursor: "pointer" },
              }}
              onClick={handleOpenProfilePicModal}
            >
              <img
                src={profilePic}
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Stack>
          )}
          {data.length > 0 &&
            data
              ?.filter(
                (item) =>
                  (item?.hasOwnProperty("cardItem") === false ||
                    item?.cardItem === false) &&
                  !(item?.hasOwnProperty("data") && !item.data)
              )
              .map((field, i) => (
                <Stack
                  key={i}
                  width={field.width || "47%"}
                  direction={!mobileView ? "row" : "column"}
                  alignItems={"flex-start"}
                  gap={2}
                  borderBottom="0.5px solid rgba(0,0,0,0.2)"
                  paddingBottom={3}
                  paddingTop={3}
                >
                  <Typography variant="body2">
                    {!mobileView
                      ? i18n.language === "en"
                        ? field.label?.toUpperCase()
                        : t(field.label)
                      : t(field.label)}
                  </Typography>
                  <Stack flex={1}>{addListField(field)}</Stack>
                </Stack>
              ))}
        </Stack>
      )}
    </Stack>
  );
}

const ProfilePicModal = ({
  profilePic,
  closeHandler,
  updateProfilePicHandler,
}) => {
  const [uploadedProfilePic, setUploadedProfilePic] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //api

  const onChangeProfilePic = (event) => {
    if (event.target.files?.[0]) {
      setUploadedProfilePic(event.target.files[0]);
    }
  };

  const saveHandler = async () => {
    setLoading(true);
    const profilePicBase64 = await getBase64(uploadedProfilePic);
    const res = await updateProfilePicHandler(profilePicBase64);
    setLoading(false);
    if (res.ok) closeHandler();
  };

  return (
    <Stack gap={3}>
      {updateProfilePicHandler && (
        <Stack direction={"row"} alignItems="center" gap={2}>
          <Typography fontSize={20} fontWeight={500}>
            {t("Update your profile picture")}
          </Typography>
          <Button
            component="label"
            endIcon={<UploadIcon />}
            style={{
              textTransform: "none",
              color: "#8C8C8C",
              alignSelf: "center",
            }}
          >
            {t("Upload")}
            <input
              type="file"
              onChange={onChangeProfilePic}
              style={{ width: 0 }}
            />
          </Button>
        </Stack>
      )}
      <Stack
        width="300px"
        height="300px"
        borderRadius="150px"
        overflow="hidden"
        border="2px solid"
        alignSelf={"center"}
      >
        <img
          alt="preview image"
          src={
            uploadedProfilePic
              ? URL.createObjectURL(uploadedProfilePic)
              : profilePic
          }
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />
      </Stack>
      {updateProfilePicHandler && (
        <Stack direction="row" justifyContent={"flex-end"} gap={2}>
          <Button
            onClick={closeHandler}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={saveHandler}
            variant="contained"
            sx={{ textTransform: "none" }}
            disabled={!uploadedProfilePic}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size="20px" />
            ) : (
              t("Save")
            )}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
